<template>
  <wsDialog v-model="display"
            :title="$t('TryNowTitle')"
            hideButtons
            :fullscreen="displayShow(['sm'])"
            :width="loggedIn ? '1100' : '500'"
            blank no-padding>

    <v-sheet   :height="displayShow(['sm']) ? '100vh' : '' ">
      <v-sheet
                class="overflow-y-hidden"
                :class="displayShow(['sm']) ? 'px-3' : 'px-6 py-3' " >

        <!-- Header Title and Cancel button -->
        <v-sheet width="20"/>
        <div class="d-flex align-center justify-space-between">
          <div>
            <h3 v-if="step !==4" class="  text-center">
              {{ is_westudy ? $t('CreateWestudyAccount') : $t('CreateBusinessAccount') }}
            </h3>
            <h3 v-if="step ===4" class=" font-weight-light text-center">
              {{ is_westudy ? "WeStudy Online": "FineTrading" }}
            </h3>
          </div>
          <v-btn @click="display=false" icon><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <h4 style="line-height: 1.2" class="font-weight-medium mt-3" >{{  subtitle }}   </h4>
        <!-- Main Content -->
        <v-sheet style="width: 100%"
                 :min-height="displayShow(['sm']) ? '' : '300' "
                 :class="displayShow(['sm']) ? '' : 'd-flex align-center fill-height' " >

          <v-sheet style="width: 100%" >
            <!-- Auth window-->
            <div v-if="!loggedIn" class="">
              <authWindow  @success="authFinished"  full-size no-toggle
                           display-register
                           :class="displayShow(['sm']) ? 'mt-3' : 'mt-9' "
              />
            </div>
            <!-- Main Content -->
            <div v-else>

              <!-- Business name and alias-->
              <div v-if="step === 0">
                <v-text-field v-model="entityData.name" class="mt-16"
                              noBorder="true"
                              outlined
                              :placeholder="$t('Name')"
                />

                <v-text-field  v-model="entityData.alias"
                               @click="aliasError = ''"
                               :error-messages="aliasError"
                               outlined
                               placeholder="Постійне посилання"
                               height=""
                               required >
                  <template #prepend-inner>
                    <span class="font-weight-medium" style="padding-top:3px">westudy.ua/</span>
                  </template>
                </v-text-field>

                <wsCheckBox class="mt-n3" :small="false" v-model="termsConfirmed">
                  <h5 class="font-weight-light">
                    Підтверджую згодну з умовами та правилами використання ресурсу WeStudy.
                    <span style="cursor : pointer; color : cornflowerblue !important" @click="displayTerms = true" class="font-weight-bold text--primary ml-1">
                      Дивитись умови та правила <v-icon color="cornflowerblue" small>mdi-chevron-right</v-icon></span>
                  </h5>
                </wsCheckBox>

                <h5 class="font-weight-light text-left mt-6 ml-2">{{ $t('YourSiteWillBeAvailableByLink') }}: <span class="font-weight-bold">westudy.ua/{{entityData.alias ? entityData.alias : $t('Alias')}}</span>   </h5>


              </div>
              <!-- Business type-->
              <div v-if="step === 1 ">
                <div  class="d-flex flex-wrap  justify-center mt-6">
                  <div @click="entityData.business_type = item.value"  v-for="(item,i) in  templateType" :key="i"  class="mr-3 mt-6">
                    <v-chip @click="entityData.business_type = item.value"
                            :outlined="entityData.business_type !== item.value"
                            :dark="entityData.business_type === item.value"
                            large
                            :color="item.color"
                            rounded class="px-6" >
                      <h4 class="font-weight-medium">{{ item.text }}</h4>
                    </v-chip>
                  </div>
                </div>
              </div>
              <!-- Business Activity Type -->
              <div v-if="step === 2 ">

                <avalon-create-site-dialog />

                <div  class="d-flex flex-wrap  justify-center mt-6">
                  <div @click="entityData.business_design = item.value"  v-for="(item,i) in  templateActivity" :key="i"  class="mr-3 mt-6">
                    <v-chip @click="entityData.business_design = item.value"
                            :outlined="entityData.business_design !== item.value"
                            :dark="entityData.business_design === item.value"
                            large
                            :color="item.color"
                            rounded class="px-6" >
                      <h4 class="font-weight-medium">{{ item.text }}</h4>
                    </v-chip>
                  </div>
                </div>

                <h5 class="font-weight-light text-left mt-6 ml-2 text-center">{{ $t('TemplateContentDescription') }}   </h5>


              </div>
              <!-- Business plan-->
              <div v-if="step === 3 ">

                <div class="d-flex flex-wrap justify-center">
                  <v-sheet v-for=" (plan,i) in businessPlans" :key="i"
                           @click="entityData.business_plan = plan.alias"
                           :color="entityData.business_plan === plan.alias ? wsDARK : wsLIGHTCARD"
                           :dark="entityData.business_plan === plan.alias"
                           class="mt-6 mx-3 wsRoundedHalf pointer"
                           width="420">

                    <v-sheet  dark :color="plan.color" elevation="0" class="wsRoundedTop py-1 px-6" />
                    <v-row class="px-3">
                      <v-col>
                        <div class="d-flex fill-height align-center">
                          <h4 class="text-center font-weight-medium mt-3">{{ plan.name }} </h4>
                        </div>
                      </v-col>
                      <v-col>
                        <h3 class="text-right font-weight-medium mt-1"> {{ plan.price !== 0 ? plan.price + ' $' : $t('Free')}} </h3>
                        <h6 class="text-right font-weight-light">{{ plan.price !== 0 ? $t('perMonth') + ' $' : $tc('day',plan.trial_days,{n:plan.trial_days}) }}</h6>
                      </v-col>
                    </v-row>
                    <v-divider class="my-3" />
                    <v-row class="px-3 pb-3">
                      <v-col cols="6">
                        <div class="d-flex fill-height align-center">
                          <div>
                            <h6 v-for="(module,j) in plan.modules" :key="i +'module' + j" class="font-weight-medium text-left">
                              <v-icon small color="green lighten-1" class="mr-1">mdi-check</v-icon>{{ module.name }}</h6>
                          </div>
                        </div>

                      </v-col>
                      <v-col cols="6">

                        <div class="d-flex fill-height align-center">
                          <div>
                            <h6 class="font-weight-medium text-left">
                              <v-icon small color="green lighten-1" class="mr-1">mdi-school </v-icon>
                              {{ $t('Courses') }}:  <span class="font-weight-bold"> {{ plan.quota_courses || $t('Unlimited')  }} </span>
                            </h6>
                            <h6 class="font-weight-medium text-left">
                              <v-icon small color="green lighten-1" class="mr-1">mdi-account-school </v-icon>
                              {{ $t('StudentProfiles') }}:  <span class="font-weight-bold">{{  plan.quota_students  }}</span>
                            </h6>
                            <h6 class="font-weight-medium text-left">
                              <v-icon small color="green lighten-1" class="mr-1">mdi-account-tie </v-icon>
                              {{ $t('Managers') }}:  <span class="font-weight-bold">{{  plan.quota_managers  }}</span>
                            </h6>
                            <h6 class="font-weight-medium text-left">
                              <v-icon small color="green lighten-1" class="mr-1">mdi-database </v-icon>
                              {{ $t('Storage') }}:  <span class="font-weight-bold">{{  plan.quota_storage  }} GB</span>
                            </h6>
                          </div>
                        </div>

                      </v-col>
                    </v-row>

                  </v-sheet>
                </div>

              </div>
              <!-- Success window plan-->
              <div v-if="step === 4 "  >

                <v-sheet :color="wsLIGHTCARD" class="pa-6 mt-4 wsRoundedHalf">
                  <v-icon size="92" color="green lighten-2">mdi-checkbox-marked-circle-outline</v-icon>

                  <h3 class="mt-6 text-center">
                    {{ $t("RegistrationWelcomeText",{business : $store.state.business.selectedBusiness.name}) }}</h3>
                  <h5 class="font-weight-light mt-3">{{ $t("TemplateContentExplanation") }}</h5>

                </v-sheet>
              </div>


            </div>

          </v-sheet>
        </v-sheet>

      </v-sheet>

      <!-- Navigation buttons -->
      <div v-if="loggedIn && step !== 4" class="d-flex justify-space-between"
           :class="displayShow(['sm']) ? 'pt-6' : 'pt-8 px-8 mb-0' " >

        <div>
          <v-btn class="mr-3 noCaps" :large="!SM"  v-if="step > 0"
                 :width="displayHide(['sm']) ? '140' : ''"
                 @click="cancelButton" elevation="0"    color="grey" outlined rounded >
            <v-icon v-if="step > 0">mdi-chevron-left</v-icon>
            {{ cancelText }}
          </v-btn>
        </div>

        <v-btn :large="displayHide(['sm'])"  :loading="LOADING"  @click="saveButton" elevation="0" width="140" dark :color="wsACCENT" rounded class="noCaps ">
          {{ saveText }}
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <!-- Success Registration Buttons -->
      <div v-if=" step === 4"   class=" justify-space-between pt-8 px-8 mb-0" :class="!SM ? 'd-flex' : '' ">
        <v-btn :large="displayHide(['sm'])"  :to="businessPublicLink('')" elevation="0" text :color="wsACCENT" rounded class="noCaps">{{ $t('VisitPublicPage')  }}</v-btn>
        <v-btn :large="displayHide(['sm'])"  :to="businessDashLink('')" elevation="0"  dark :color="wsACCENT" rounded class="noCaps">{{ $t('VisitBusinessDashboard')  }}</v-btn>
      </div>

    </v-sheet>

    <ws-dialog v-model="displayTerms"
               width="1100"
               :cancel-text="$t('Back')"
               :save-text="$t('Confirm')"
               @save="confirmRules"
               :title="$t('TermsAndConditions')">

      <h3 class="font-weight-medium ">Правила та умови користування сервісом WeStudy Online викладені в наступних документах</h3>
      <h4 class="mt-3 font-weight-light ">Підтверджуючи умови користування, ви надаєте згоду зі змістом нижче перелечіних документів</h4>

      <v-simple-table  class="my-6">
        <tbody>
          <tr><td> <router-link target="_blank" class="noUnderline primary--text" :to="localeLink('about/terms')"><h3 class="font-weight-medium">Умови та правила використання </h3></router-link> </td> </tr>
          <tr><td> <router-link target="_blank" class="noUnderline primary--text" :to="localeLink('about/confidential')"><h3 class="font-weight-medium">Політика конфіденційності </h3></router-link> </td> </tr>
          <tr><td> <router-link target="_blank" class="noUnderline primary--text" :to="localeLink('about/offer')"><h3 class="font-weight-medium">Ліцензійний публічний договір (Публічна Оферта) </h3></router-link> </td> </tr>
          <tr><td> <router-link target="_blank" class="noUnderline primary--text" :to="localeLink('about/refund')"><h3 class="font-weight-medium">Правила повернення коштів </h3></router-link> </td> </tr>
        </tbody>
      </v-simple-table>


      <h2 class="font-weight-medium mt-6">ЛІЦЕНЗІЙНИЙ ПУБЛІЧНИЙ ДОГОВІР (ПУБЛІЧНА ОФЕРТА)</h2>
      <h2 class="font-weight-medium mt-3">    Основні положення</h2>

      <h4 class="font-weight-medium my-3">Клієнт має право:</h4>
      <ul style="list-style-type: square;">
        <li>використовувати Платформу відповідно до умов та в межах, встановлених цим Договором та Додатками до нього;</li>
        <li>звернутися у будь-який час за контактними даними ТОВ “ВІСТАДІ”, зазначеними на веб-сайті Платформи https://westudy.ua/online для отримання консультацій та роз’яснень;</li>
        <li>розірвати цей Договір у відповідності до умов цього Договору та Додатків до нього;</li>
        <li>інші права, зазначені в цьому Договорі та Додатках до нього.</li>
      </ul>
      <h4 class="font-weight-medium my-3"> ТОВ “ВІСТАДІ” зобов’язується:</h4>
      <ul style="list-style-type: square;">
         <li>надати Ліцензіату Ліцензію на використання Платформи;</li>
         <li>своєчасно та сумлінно виконувати умови цього Договору та Додатків до нього;</li>
         <li>робити все належне для того, щоб робота Платформи відповідала умовам цього Договору та Додатків до нього, описам та інструкціям, опублікованим ТОВ “ВІСТАДІ” та щоб Користувачі могли в повній мірі користуватися Платформою, її Функціональними Площадками та її технічними можливостями 24 години на добу із забезпеченням необхідного рівня безпеки доступу.</li>
         <li>Користування Платформою</li>
         <li>Будь-який Користувацький Контент має відповідні права, у тому числі авторські права та/або інші права інтелектуальної власності та/або матеріальні права.</li>
      </ul>
      <h4 class="font-weight-medium my-3">Відповідальність Сторін:</h4>
      <ul style="list-style-type: square;">
         <li>Користувач несе відповідальність за свої дії в рамках функціонування Платформи у відповідності до законодавства України.</li>
         <li>ТОВ “ВІСТАДІ” не несе відповідальності за зміст Користувацького Контенту та наслідки публікації даного контенту.</li>
      </ul>
      <h4 class="font-weight-medium my-3"> Конфіденційність: </h4>
      <ul style="list-style-type: square;">
        <li>Сторони зобов’язуються не розголошувати конфіденційну інформацію і не використовувати її, окрім як для виконання зобов’язань за цим Договором. Сторона, якій надана конфіденційна інформація, зобов’язується вживати заходи до її захисту не менші, ніж заходи прийняті нею для захисту власної конфіденційної інформації, вживати заходи для попередження несанкціонованого використання або розкриття такої інформації третім особам.</li>
        <li>Термін дії Ліцензії</li>
        <li>Термін дії Ліцензії — період часу протягом якого Ліцензіат має право на використання Платформи відповідно до умов цього Договору, відповідає терміну дії підписки за тарифом на Платформі, яку було обрано та сплачено Ліцензіатом. Договір набуває чинності з моменту його акцепту, тобто календарної дати здійснення реєстрації та авторизації Профілю Користувача.</li>
      </ul>

      </ws-dialog>

  </wsDialog>
</template>

<script>
import authWindow from "@/components/auth/authWindow";
import {mapActions, mapState} from "vuex";
import AvalonCreateSiteDialog from "@/components/AvalonEditor/Dialogs/newSiteDialog/AvalonCreateSiteDialog";
export default {
  name: "registerBusiness",
  components : {
    AvalonCreateSiteDialog,
    authWindow
  },
  props : {
    value : {
      type : Boolean,
      default : false
    },
  },
  data() {
    return {
      termsConfirmed : false,
      displayTerms : false,
      entityData : {
        name : "",
        business_type : "",
        business_plan : "",
        business_design : "",
        registrationSuccess : false,
      },
      display : false,
      step : 0,

      businessPlans : [],

      // errors
      aliasError : ''
    }
  },
  computed : {
    ...mapState('auth', ['loggedIn']),

    subtitle() {
      if (!this.loggedIn) { return this.$t('PleaseLoginToProceed')  }
      switch (this.step) {
        case 0 : return this.$t('NameOfYourBusiness')
        case 1 : return this.$t('ChooseBusinessType')
        case 2 : return this.$t('ChooseBusinessActivityType')
        case 3 : return this.$t('ChooseBusinessPlan')
        case 4 : return ""
        default : return ''
      }
    },
    templateType() {
      if ( this.$store.state.project === 'fine') {
        return [
          { text : this.$t('Restaurant'),           value : 'restaurant', color:'blue', active:false },
          { text : this.$t('FoodDelivery'),         value : 'food_delivery', color:'teal', active:false },
          { text : this.$t('ProductsSell'),         value : 'shop', color:'green', active:false },
          { text : this.$t('ServicesSell'),         value : 'services', color:'pink', active:false },
          { text : this.$t('Events'),               value : 'events', color:'blue darken-1 ', active:false },
          { text : this.$t('OnlineCourses'),        value : 'westudy', color:'primary', active:false },
        ]
      }
      if ( this.$store.state.project === 'westudy') {
        return [
          { text : this.$t("PrivateSchool"      ),       value : 'private_school',     color:'blue',  active:false },
          { text : this.$t("BusinessTrainings"  ),       value : 'business_trainings', color:'teal',  active:false },
          { text : this.$t("PsychologyCourses"  ),       value : 'psychology_courses', color:'pink',  active:false },
          { text : this.$t("ImprovementCourses" ),       value : 'upgrade',            color:'green', active:false },
          { text : this.$t("ChildCourses"       ),       value : 'children',           color:'green',  active:false },
          { text : this.$t("FitnessSportTeacher"),       value : 'fitnes',             color:'teal',  active:false },
          { text : this.$t("ArtCourses"         ),       value : 'art',                color:'blue', active:false },
          { text : this.$t("SchoolTeacher"      ),       value : 'school_teacher',     color:'pink',  active:false },
        ]
      }
      return []
    },
    templateActivity() {

      let types = {
        westudy: [
          { text : this.$t("PrivateSchool"),       value : 'private_school'    , color: 'blue' ,   active: false},
          { text : this.$t("BusinessTrainings"),   value : 'business_trainings', color: 'teal' ,   active: false},
          { text : this.$t("PsychologyCourses"),   value : 'psychology_courses', color: 'pink' ,   active: false},
          { text : this.$t("ImprovementCourses"),  value : 'upgrade'           , color: 'green',   active: false},
          { text : this.$t("ChildCourses"),        value : 'westudy_children'  , color: 'green',   active: false},
          { text : this.$t("FitnessSportTeacher"), value : 'westudy_sport'     , color: 'teal' ,   active: false},
          { text : this.$t("ArtCourses"),          value : 'art'               , color: 'blue' ,   active: false},
          { text : this.$t("SchoolTeacher"),       value : 'school_teacher'    , color: 'pink' ,   active: false},
          { text : this.$t('OtherType'),           value : 'westudy_default'   , color: 'primary', active: false },

        ],
        restaurant: [
          { text : this.$t("Pizza"),        value : 'psychology_courses', color: 'pink' ,       active: false},
          { text : this.$t("Sushi"),        value : 'psychology_courses', color: 'pink' ,       active: false},
          { text : this.$t("Cafeteria"),    value : 'psychology_courses', color: 'pink' ,   active: false},
          { text : this.$t("Restaurant"),   value : 'psychology_courses', color: 'pink' ,   active: false},
        ]

      };
      if ( types[this.entityData.business_type] ) {
        return types[this.entityData.business_type]
      }
      return []
    } ,
    saveText() {
      return this.$t('Next')
    },
    cancelText() {
      return this.step === 0 ? this.$t('Return') : this.$t('Back')
    }
  },
  watch : {
    display(value) {
      this.$emit('input',value)
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
      if ( this.step === 4 ) {
        this.step=0
      }
    }
  },
  methods : {
    ...mapActions('business',  [ 'ADD_BUSINESS' , 'CHECK_ALIAS' , 'GET_CLIENT_PLANS' ]),
    ...mapActions('auth',  [ 'GET_USER' ]),
    async addBusiness() {
      this.loading = true
      this.entityData.registration_device = this.DEVICE
      let result = await this.ADD_BUSINESS(this.entityData)
      if ( !result ) { return false }
      await this.GET_USER();

      this.$store.state.business.selectedBusiness.alias = this.entityData.alias
      this.$store.state.business.selectedBusiness.name = result.name

      return true

    },
    authFinished() {
      this.notify('')
    },
    async saveButton() {
      switch (this.step) {

        case 0 :

          if ( !this.termsConfirmed ) {
            return this.notify(this.$t('PleaseConfirmRulesAndConditions'))
          }

          if ( !this.entityData.alias ) {
            this.aliasError = this.$t('PleaseEnterBusinessAlias')
            return this.notify(this.$t('PleaseEnterBusinessAlias')) }



          if ( !this.entityData.alias.match("^[a-zA-Z0-9_-]*$")) {
            this.aliasError = this.$t('AliasRegexError')
            return this.notify(this.$t('AliasRegexError'))
          }

          if ( this.entityData.alias.length < 3) {
            this.aliasError = this.$t('AliasLengthError')
            return this.notify(this.$t('AliasLengthError'))
          }

          if ( !await this.CHECK_ALIAS(this.entityData.alias) ) {
            this.aliasError = this.$t('BusinessAliasAlreadyExist')
            return this.notify(this.$t('BusinessAliasAlreadyExist'))
          }
          if ( this.is_westudy ) { this.step++ }
          break;
        case 1 :  if ( !this.entityData.business_type ) { return this.notify(this.$t('PleaseEnterBusinessType'))  } break;
        case 2 :
          this.businessPlans = await this.GET_CLIENT_PLANS('westudy') ?? []
          // if ( !this.entityData.business_design ) {
          // return this.notify(this.$t('PleaseEnterActivityType'))  }
          break;
        case 3 :
          if ( !this.entityData.business_plan ) { return this.notify(this.$t('PleaseEnterBusinessPlan'))  }

          if ( !await this.addBusiness() ) { return this.notify(this.$t('NetworkError')) }

          break;

      }
      this.step++
    //  if ( this.step === 4 ) { }
      if ( this.step === 5 ) { return this.display = false }
    },
    cancelButton() {
      if ( this.is_westudy && this.step === 2 ) { this.step-- }
      return this.step === 0 ? this.display = false :  this.step--
    },
    confirmRules() {
      this.termsConfirmed = true;
      this.displayTerms = false;
    }
  },
  async mounted() {

    this.businessPlans = await this.GET_CLIENT_PLANS('westudy') ?? []

    this.display = this.value
    if ( this.is_westudy ) {
      this.entityData.business_type = 'westudy'
      this.entityData.business_design = 'private_school'
    }

  }
}
</script>

<style scoped>

</style>