<template>
  <div>
    <!-- MAIN SLIDER-->
    <v-sheet class="d-flex justify-center" dark :color="wsDARKER" >
      <v-sheet  class="pa-16 px-8" color="transparent" width="1200">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <div class="fill-height d-flex align-center px-0">
              <div >
                <v-img v-if="displayShow(['sm'])"
                       class="wsRounded mx-auto"
                       width="200"
                       aspect-ratio="1.1"
                       src="@/assets/img/login_img.png"
                       transition="xxx"
                />
                <h2 :class="displayShow(['sm']) ? 'text-center' : ''" ><span :style="`color : ${wsACCENT}`">Цифровізація</span> бізнесу </h2>
                <h3 class="font-weight-light" :class="displayShow(['sm']) ? 'text-center' : ''" >Увімкни свій бізнес на повну </h3>

                <v-sheet style="transition: all 0.5s ease; margin-top: -1px; cursor: pointer"
                         v-for="(slide,i) in sliderData" :key="i"
                         @click="slide.active = !slide.active"
                         :class="i === 0 ? 'mt-6' : ''"
                         :color="slide.active ? wsDARK : 'transparent'"

                         :height="displayHide(['sm']) ? '110' : '130'"  class="d-flex align-center py-8  pa-4 wsRoundedHalf">
                  <div class="d-flex align-start ">
                    <v-icon class="mr-3 pt-1 " size="20" :color="wsACCENT">mdi-check-circle</v-icon>
                    <h5  class="font-weight-medium mt-0 pt-0 ">{{ slide.text }}</h5>
                  </div>
                </v-sheet>

                <div :class="displayShow(['sm']) ? 'justify-center' : ''"
                     class="d-flex just mt-10">
                  <v-btn @click="displayTryNow" large elevation="0"  dark :color="wsATTENTION" class="noCaps wsRoundedButton px-5">
                    {{ $t('TryNow') }}</v-btn>
                  <v-btn large v-if="!$store.state.auth.loggedIn"
                         text
                         class="noCaps wsRoundedButton mr-4 px-6"
                         elevation="0">
                    Увійти
                  </v-btn>
                </div>

              </div>
            </div>
          </v-col>
          <v-col v-if="displayHide(['sm'])" cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pl-16" >
            <div class="d-flex justify-center">
              <v-img class="wsRounded" aspect-ratio="1.3" src="@/assets/img/login_img.png" transition="xxx" />
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>
    <!-- DASHBOARD-->
    <v-sheet  class="d-flex justify-center" :color="wsLIGHTCARD" >
      <v-sheet   :class="displayHide('sm') ? 'pa-16' : 'pa-8'" color="transparent" width="1200">
        <h1 v-if="displayHide('sm')" :style="`color: ${wsDARKLIGHT}`" class="text-center mb-8">FineTrading™ - унікальна цифрова платформа для ведення бізнесу</h1>
        <h4 v-else :style="`color: ${wsDARKLIGHT}`" class="text-center mb-8">FineTrading™ - унікальна інтегрована онлайн платформа для закладів освіти</h4>

        <v-img  class="wsRounded mx-auto  " aspect-ratio="1.67" contain src="@/assets/img/fine_dashboard.png" transition="xxx" />
      </v-sheet>
    </v-sheet>
    <!-- LEAD CONTACT WINDOW -->
    <v-sheet ref="apply" class="d-flex justify-center" color="white" >
      <v-sheet :class="displayHide(['sm']) ? 'pa-16' : 'pa-6'"  color="transparent" width="1200">
        <div v-if="displayHide(['sm'])">
          <h1 :style="`color: ${wsDARK}`" class="text-center ">Залишити заявку</h1>
          <h3  class="text-center mb-8 font-weight-medium mt-3">Запишіться на детальну консультацію та презентацію можливостей FineTrading </h3>
        </div>
        <div v-else>
          <h3 :style="`color: ${wsDARK}`" class="text-center ">Залишити заявку</h3>
          <h6  class="text-center mb-8 font-weight-medium mt-3">Запишіться на детальну консультацію та презентацію можливостей FineTrading </h6>
        </div>

        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <h5 class="font-weight-medium">Назва компанії:</h5>
            <v-text-field v-model="entityData.university" dense outlined placeholder="Назва компанії"> </v-text-field>
            <h5 class="font-weight-medium">Ім'я:</h5>
            <v-text-field v-model="entityData.name" dense   outlined   placeholder="Ім'я"> </v-text-field>
            <v-row>
              <v-col cols="6">
                <h5 class="font-weight-medium">Teлефон:</h5>
                <v-text-field v-model="entityData.phone" dense   outlined   placeholder="+38 063 754 33 43"> </v-text-field>
              </v-col>
              <v-col cols="6">
                <h5 class="font-weight-medium">Email:</h5>
                <v-text-field v-model="entityData.email" dense   outlined   placeholder="Email"> </v-text-field>
              </v-col>
            </v-row>

            <h5 class="font-weight-medium">Тип:</h5>
            <v-select :items="businessTypes" v-model="entityData.position" dense  outlined placeholder="Тип бізнесу"> </v-select>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" >
            <h5 class="font-weight-medium">Коментар:</h5>
            <v-textarea class="noResize" height="295" v-model="entityData.comment" dense  outlined placeholder="Коментар"> </v-textarea>
          </v-col>
        </v-row>

        <v-btn :loading="leadLoading"
               @click="addLead"
               :x-large="displayHide(['sm'])"
               :large="displayShow(['sm'])"
               :block="displayShow(['sm'])"
               class="noCaps px-8"
               :color="wsDARK" dark>Залишити заявку</v-btn>

      </v-sheet>
    </v-sheet>
    <!-- TECHNOLOGIES SLIDER-->
    <v-sheet class="d-flex justify-center" color="white" >
      <v-sheet   :class="displayHide(['sm']) ? 'pa-16' : 'pa-6'"  color="transparent" width="1200">
        <v-row>
          <v-col align-self="center" cols="12" xs="12" sm="12" md="3" lg="3" xl="3" >


            <div v-if="displayHide(['sm']) ">
              <h1 :class="displayShow(['sm']) ? 'text-center' : ''"
                  class="font-weight-medium  "
                  style="line-height: 1.2"
                  :style="`color: ${wsDARK}`">
                FineTrading™ використовує:
              </h1>
              <h3 :class="displayShow(['sm']) ? 'text-center' : ''"
                  class="mt-2 font-weight-light ">
                Інтеграції та технології
              </h3>
            </div>
            <div v-else>
              <h4 class="font-weight-medium text-center "
                  style="line-height: 1.2"
                  :style="`color: ${wsDARK}`">
                FineTrading™ використовує:
              </h4>
              <h6 class="mt-2 font-weight-light text-center">
                Інтеграції та технології
              </h6>
            </div>







          </v-col>
          <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
            <div style="width: 100%;">
              <div class="d-flex justify-space-between align-center " :class="displayHide(['sm']) ? 'pa-6' : '' ">
                <v-img transition="xxx" class="pa-1 ml-n1  mr-7" aspect-ratio="3.7" alt="FineTrading" src="@/assets/img/logos/vuejs.png"         />
                <v-img transition="xxx" class="mr-7"             aspect-ratio="3.7" alt="FineTrading" src="@/assets/img/logos/logo_symfony.png"  />
                <v-img transition="xxx" class="pa-1 mr-7"        aspect-ratio="3.7" alt="FineTrading" src="@/assets/img/logos/ios.png"           />
                <v-img transition="xxx" class="pt-3  "           aspect-ratio="5" alt="FineTrading" src="@/assets/img/logos/android.png"       />

              </div>
              <div class="d-flex justify-space-between align-center  " :class="displayHide(['sm']) ? 'pa-6' : '' ">
                <v-img transition="xxx" class="mr-7"      aspect-ratio="3.7"  alt="FineTrading" src="@/assets/img/logos/logo_gsuite.png"       />
                <v-img transition="xxx" class="mr-7"      aspect-ratio="3.7"  alt="FineTrading" src="@/assets/img/logos/logo_microsoft.png"   />
                <v-img transition="xxx" class="pt-1 mr-7" aspect-ratio="3.7"  alt="FineTrading" src="@/assets/img/logos/logo_amazon.png"  />
                <v-img transition="xxx" class="pt-3"      aspect-ratio="3.7"  alt="FineTrading" src="@/assets/img/logos/logo_ali2.png"    />
              </div>
            </div>
          </v-col>
        </v-row>

      </v-sheet>
    </v-sheet>
    <!-- FEATURES-->
    <v-sheet class="d-flex justify-center" :color="wsBACKGROUND" >
      <v-sheet  :class="displayHide(['sm']) ? 'pa-16' : 'pa-6'" color="transparent" width="1200">
        <div v-if="displayHide(['sm'])">
          <h1 :style="`color: ${wsDARK}`" class="text-center ">Сучасні рішення для управління бізнесом  </h1>
          <h3  class="text-center mb-8 font-weight-medium mt-3">Повноцінний пакет всіх необхідних для ведення бізнесу сучасних IT інструментів в декілька кліків </h3>
        </div>
        <div v-else>
          <h4 :style="`color: ${wsDARK}`" class="text-center ">Сучасні рішення для управління бізнесом  </h4>
          <h6  class="text-center mb-8 font-weight-medium mt-3">Повноцінний пакет всіх необхідних для ведення бізнесу сучасних IT інструментів в декілька кліків</h6>
        </div>


        <v-row>
          <v-col v-for="(item,i) in solutions" :key="i"
                 cols="12" xs="12" sm="12" md="6" lg="4" xl="4"
                 class="pa-6" >
            <v-sheet class="wsRoundedHalf pa-6" :color="wsLIGHTCARD" :height="displayHide(['sm']) ? '250' : '270' ">
              <v-avatar size="48">
                <v-sheet :color="wsBACKGROUND"  width="64" height="64">
                  <v-icon :color="wsDARK">{{  item.icon  }}</v-icon>
                </v-sheet>
              </v-avatar>
              <h5 class="mt-3">{{ item.title }}</h5>
              <h5 class="mt-3 font-weight-medium">{{ item.text }}</h5>
              <v-btn :ripple="false"   text class="noCaps mt-5 px-0" :color="wsDARKER">Детальніше <v-icon>mdi-chevron-right</v-icon></v-btn>
            </v-sheet>
          </v-col>
        </v-row>

      </v-sheet>
    </v-sheet>
    <!-- CLIENTS SLIDER-->
<!--    <v-sheet class="d-flex justify-center" color="white" >-->
<!--      <v-sheet  :class="displayHide(['sm']) ? 'pa-16' : 'pa-6'"  color="transparent" width="1200">-->
<!--        <div v-if="displayHide(['sm'])">-->
<!--          <h1 :style="`color: ${wsDARK}`" class="text-center ">FineTrading™ користуються:</h1>-->
<!--          <h3  class="text-center mb-8 font-weight-medium mt-3">Університети які користуються FineTrading™</h3>-->
<!--        </div>-->
<!--        <div v-else>-->
<!--          <h4 :style="`color: ${wsDARK}`" class="text-center ">FineTrading™ користуються:</h4>-->
<!--          <h6  class="text-center mb-8 font-weight-medium mt-3">Університети які користуються FineTrading™</h6>-->
<!--        </div>-->

<!--        <div v-if="displayHide(['sm'])">-->
<!--          <div class="d-flex justify-center align-center  ">-->
<!--            <v-sheet class="pa-6 d-flex wsRoundedHalf mr-6 align-center" :color="wsLIGHTCARD">-->
<!--              <v-avatar size="70" class="mr-3">-->
<!--                <img  height="70px"  alt="FineTrading"     src="@/assets/img/uhsp_logo.jpg"  >-->
<!--              </v-avatar>-->
<!--              <h5> Університет Григорія Сковороди в Переяславі</h5></v-sheet>-->
<!--            <v-sheet class="pa-6 d-flex wsRoundedHalf mr-6 align-center" :color="wsLIGHTCARD">-->
<!--              <v-avatar size="70" class="mr-3">-->
<!--                <img  height="70px"  alt="FineTrading"     src="@/assets/img/lpnu_logo.jpg"  >-->
<!--              </v-avatar>-->
<!--              <h5> Національний університет "Львівська політехніка"</h5></v-sheet>-->
<!--            <v-sheet class="pa-6 d-flex wsRoundedHalf align-center"     :color="wsLIGHTCARD">-->
<!--              <v-avatar size="70" class="mr-3">-->
<!--                <img  height="70px"  alt="FineTrading"     src="@/assets/img/mnu_logo.jpg"   >-->
<!--              </v-avatar>-->
<!--              <h5>Миколаївський національний університет імені В. О. Сухомлинського</h5></v-sheet>-->
<!--          </div>-->
<!--          <div class="d-flex justify-center align-center mt-6 ">-->
<!--            <v-sheet max-width="320" class="pa-6 d-flex wsRoundedHalf mr-6 align-center" :color="wsLIGHTCARD">-->
<!--              <v-avatar size="70" class="mr-3">-->
<!--                <img  height="70px"  alt="FineTrading"     src="@/assets/img/lnu_logo.jpg"   >-->
<!--              </v-avatar>-->
<!--              <h5>Волинський національний університет імені Лесі Українки</h5>-->
<!--            </v-sheet>-->
<!--            <v-sheet max-width="320" class="pa-6 d-flex wsRoundedHalf  align-center   " :color="wsLIGHTCARD">-->
<!--              <v-avatar size="70" class="mr-3">-->
<!--                <img  height="70px"  alt="FineTrading"     src="@/assets/img/udpu_logo.jpg"  >-->
<!--              </v-avatar>-->
<!--              <h5>Уманський державний педагогічний університет імені Павла Тичини</h5>-->
<!--            </v-sheet>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-else>-->
<!--          <v-sheet style="width: 100%" class="pa-6 mt-5 d-flex wsRoundedHalf mr-6 align-center" :color="wsLIGHTCARD" mt-5>-->
<!--            <v-avatar size="70" class="mr-3">-->
<!--              <img  height="70px"  alt="FineTrading"     src="@/assets/img/uhsp_logo.jpg"  >-->
<!--            </v-avatar>-->
<!--            <h5> Університет Григорія Сковороди в Переяславі</h5></v-sheet>-->
<!--          <v-sheet style="width: 100%" class="pa-6 mt-5 d-flex wsRoundedHalf mr-6 align-center" :color="wsLIGHTCARD">-->
<!--            <v-avatar size="70" class="mr-3">-->
<!--              <img  height="70px"  alt="FineTrading"     src="@/assets/img/lpnu_logo.jpg"  >-->
<!--            </v-avatar>-->
<!--            <h5> Національний університет "Львівська політехніка"</h5></v-sheet>-->
<!--          <v-sheet style="width: 100%" class="pa-6 mt-5 d-flex wsRoundedHalf align-center"     :color="wsLIGHTCARD">-->
<!--            <v-avatar size="70" class="mr-3">-->
<!--              <img  height="70px"  alt="FineTrading"     src="@/assets/img/mnu_logo.jpg"   >-->
<!--            </v-avatar>-->
<!--            <h5>Миколаївський національний університет імені В. О. Сухомлинського</h5></v-sheet>-->
<!--          <v-sheet style="width: 100%" class="pa-6 mt-5 d-flex wsRoundedHalf mr-6 align-center" :color="wsLIGHTCARD">-->
<!--            <v-avatar size="70" class="mr-3">-->
<!--              <img  height="70px"  alt="FineTrading"     src="@/assets/img/lnu_logo.jpg"   >-->
<!--            </v-avatar>-->
<!--            <h5>Волинський національний університет імені Лесі Українки</h5>-->
<!--          </v-sheet>-->
<!--          <v-sheet style="width: 100%" class="pa-6 mt-5 d-flex wsRoundedHalf  align-center   " :color="wsLIGHTCARD">-->
<!--            <v-avatar size="70" class="mr-3">-->
<!--              <img  height="70px"  alt="FineTrading"     src="@/assets/img/udpu_logo.jpg"  >-->
<!--            </v-avatar>-->
<!--            <h5>Уманський державний педагогічний університет імені Павла Тичини</h5>-->
<!--          </v-sheet>-->
<!--        </div>-->
<!--      </v-sheet>-->
<!--    </v-sheet>-->
    <!-- POSIBILITIES SLIDER-->
    <v-sheet class="d-flex justify-center mb-n10" dark :color="wsDARK" >
      <v-sheet  :class="displayHide(['sm']) ? 'pa-16' : 'pa-6'"  color="transparent" width="1200">
        <div v-if="displayHide(['sm'])">
          <h1  class="text-center ">Можливості FineTrading™</h1>
          <h3  class="text-center mb-8 font-weight-medium mt-3">Короткий перелік основних функцій та модулей, які пропонує FineTrading™</h3>
        </div>
        <div v-else>
          <h3 :style="`color: ${wsDARK}`" class="text-center ">Можливості FineTrading™</h3>
          <h6  class="text-center mb-8 font-weight-medium mt-3">Короткий перелік основних функцій та модулей, які пропонує FineTrading™</h6>
        </div>

        <v-row class="mb-0">
          <v-col v-if="displayHide(['sm'])" cols="3" class="pa-0 text-left programs_img" style="align-content: center" >
            <div class="d-flex align-center fill-height">
              <v-img class="wsRounded my-auto" aspect-ratio="0.87" src="@/assets/img/technologies_img.png" />
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9" class="pa-16" :class="displayShow(['sm']) ? 'px-3' : ''"  >
            <v-sheet class="py-6 px-6 wsRoundedHalf" :class="displayShow(['sm'] ? 'px-1 ' : 'pa-6')"  :color="wsLIGHTCARD">
              <v-simple-table class="wsHoverDark wsRoundedHalf pa-3" light

                              dense  >
                <tbody class="wsHoverDark">
                <tr v-for="(func,i) in wsFunctions" :key="i">
                  <td class=" font-weight-light"><h5>{{  func.name  }}</h5></td>
                  <td><h5  class=" font-weight-medium">{{  func.details  }}</h5></td>
                </tr>

                </tbody>

              </v-simple-table>
            </v-sheet>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>

    <registerBusiness v-model="displayDialog"/>

  </div>
</template>

<script>
import registerBusiness from "@/components/UI/components/registerBusiness";
import {mapActions} from "vuex";

export default {
  name: "HomeMain",
  components : {
    registerBusiness
  },
  data() {
    return {

      displayDialog : false,

      leadLoading : false,
      leadNameError : '',
      leadUniversityError : '',
      leadPhoneError : '',
      leadEmailError : '',
      entityData : {
        name : '',
        position : '',
        phone : '',
        email : '',
        university : '',
        comment : '',
      },
      businessTypes : [
        { text : 'Ресторан', value : 'restaurant'},
        { text : 'Доставка їжі',  value : 'food_delivery'},
        { text : 'Доставка води', value : 'water'},
        { text : 'Інтернет магазин', value : 'shop'},
        { text : 'Сфера послуг', value : 'services'},
        { text : 'Онлайн курси', value : 'online_course'},
        { text : 'Інша', value : 'other'},
      ],
      sliderData : [
        { text: "Зручність та сучасність, це те що характеризує FineTrading. Отримай потужний універсальний інструмент для розвитку твого бізнесу. Спробувавши, ти запитаєш себе чому не робив це раніше", active : true },
        { text: "Автоматизація процесів, онлайн оплата, облік та інтеграція, склади та логістика, лідогенерація, сучасний веб сайт, мобільні додатки, чат боти та багато інших інструментів, які зʼявляються одразу за лічені хвилини після реєстрації та швидкого налаштування", active : false },
        { text: "Великий спектр кейсів та рішень саме для твого бізнесу, ресторани, доставка їжі, логістика, сфера послуг, онлайн курси та багато інших модулів ", active : false },
      ],
      solutions : [
        { title : "Веб сайт",      icon : "mdi-web",  text: "Зручний конструктор сайту, сучасні набори шаблонів та дизайнів " },
        { title : "Онлайн оплата та облік", icon : "mdi-credit-card",  text: "Онлайн оплата в один клік, цифрова бухгалтерія, облік платежів" },
        { title : "СRM модуль",   icon : "mdi-account-multiple",  text: "Зручна система менеджменту обліку та роботи із клієнтами, розсилки та оповіщення" },
        { title : "Мобільні додатки",    icon : "mdi-cellphone-arrow-down",  text: "Персональні брендовані нативні мобільні додатки iOS та Android вашого бізнесу" },
        { title : "Модуль статистика",     icon : "mdi-chart-bar",  text: "Зручний конструктор статистичних звітів бізнесу, автоматична генерація звітів" },
        { title : "Чат боти",   icon : "mdi-file",  text: "Чат боти вашого бізнесу, оплата товарів через чат боти: оповіщення та розсилки" },
        { title : "Модуль склад",   icon : "mdi-warehouse",  text: "Потужний модуль для менеджменту складів та автоматичного обліку наявності товарів" },
        { title : "Автоматизація",   icon : "mdi-desktop-classic",  text: "Автоматизація процесів бізнесу: автоматичне оброблення замовлень та розподілення та інше " },
        { title : "Модульний підхід",   icon : "mdi-view-module",  text: "Різноманітні модулі для потреб широкого спектру бізнесу" },

      ],
      wsFunctions : [
        {name: 'Персональнй Веб-Сайт із сучасним диизайном персоналізованй під ваші виимоги', details : 'Vue.js powered Web Application' },
        {name: 'Реактивність веб ресурсів та швидкодія ',details:'Vue.js, Symfony, Doctrine'},
        {name: 'Мобільні додатки вашого бізнесу',details:'iOS, Android'},
        {name: 'Cистема інтеграцій Cloud сервісів, хмарних сховищ',details:'Google Cloud Service, Goole Cloud SQL',},
        {name: 'Система оповіщень та інтеграція оповіщень через персональні чат боти бізнесу',details:'Системні повідомлення, розсилки, новини, Telegram, Viber, WeChat'},
        {name: 'Онлайн оплата в один клік, цифрова бухгалтерія, облік платежів ',details:'Liqpay, Privat24, Fondy'},
        {name: 'Муьтимовність та інтеграція із інтелектуальними системами перекладу', details: "Google Translate" },
        {name: 'WeStudy Online модуль для ведення онлайн курсів бізнес шкіл освітніх рішень',details:'WeStudy Online Module'},
        {name: 'Cистема хмарних відеосховищ, із можливістю надшвидкої міжконтинентальною потокової передачі даних',details:'AliCloud VHS, Microsoft Teams, Google Meets, Zoom, YouTube'},


      ],
    }
  },
  watch : {
    $route(to) {
      if ( to.hash ) {
        this.scrollTo(to.hash)
      }
    },
  },
  methods : {
    ...mapActions('adminCrmSystem', ['ADD_LEAD']),

    displayTryNow() {
      this.displayDialog = true
    },


    addLead() {
      let errorMessage = ''
      if ( !this.entityData.university ) { errorMessage= "Вкажіть навчальний заклад" }
      if ( !this.entityData.phone ) { errorMessage= "Вкажіть Ваш контактний телефон" }
      if ( !this.entityData.name ) { errorMessage= "Вкажіть Ваше ім'я" }

      if (errorMessage) { return this.notify(errorMessage) }

      this.leadLoading = true
      this.ADD_LEAD(this.entityData).then((out)=>{
        if ( out.result ) {
          this.notify('Заявку відправлено')
          this.entityData = {
            name : '',
            position : '',
            phone : '',
            email : '',
            university : '',
            comment : '',
          }
        }
        this.leadLoading = false
      }).catch(()=>this.leadLoading = false)
    },
    scrollTo(target) {

      if (!target) { return
        //  target = 'main'
      } else {
        target =  target.substring(1)
      }
      if ( this.$refs[target] === undefined ) { return }
      this.$vuetify.goTo(this.$refs[target][0],
          {
            duration: 300,
            offset: 70,
            easing: 'easeInOutQuad',
          })
    },
  }
}
</script>

<style lang="scss" scoped>
tbody {
  tr:hover {
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: #6F93AA !important;
    color : whitesmoke;
  }
}
.slide {
  height: 600px;
  background: url('~@/assets/img/dashboard_student.jpg');
  /* background-color: #2c3e50;*/
  background-size: contain;
  background-position: center;
  border-radius: 30px;
  color:white;
  text-align: center;
  padding:70px;
  line-height: 1.2;
}
</style>